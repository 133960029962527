import { isNull, isUndefined } from 'lodash';

/* eslint-disable no-restricted-properties */
const getPartnerAllowedLocales = (): any => {
  const partnerGlobalConfigLocal: any = JSON.parse(window.localStorage.getItem('partner_settings'));
  let allowedLocales = [];
  if (!isNull(partnerGlobalConfigLocal) && !isUndefined(partnerGlobalConfigLocal)) {
    allowedLocales = partnerGlobalConfigLocal?.allowedLocales;
  }
  return allowedLocales;
};

export default getPartnerAllowedLocales;
