import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { makeStyles } from '@material-ui/core/styles';

import {
  Grid,
} from '@material-ui/core';

import {
  LoginJWT
} from 'src/components/authentication/login';
import useAuth from 'src/hooks/useAuth';
import gtm from 'src/lib/gtm';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import jwtDecode from 'jwt-decode';
import getPartnerLogo from 'src/utils/getPartnerLogo';

const useStyles = makeStyles((theme) => ({
  leftHeroWrapper: {
    display: 'flex',
  },
  leftHeroImage: {
    maxWidth: '40%',
    background: 'white',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  fullPageHero: {
    maxWidth: '100%',
  },
  rightHeroImage: {
    minWidth: '60%',
    backgroundImage: `url(${process.env.REACT_APP_PLATFORM_DISTRIBUTOR_LOGIN_BG_IMAGE})`,
    background: 'white',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      backgroundImage: "url('https://assets.it22.nl/fortes-energy/admin/login/fortes-login-right-hero-mobile.jpg')",
    }
  },
  imagesWrapper: {
    display: 'flex'
  },
  leftSideLogo: {
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '50vw',
    [theme.breakpoints.down('md')]: {
      width: '100vw'
    }
  },
  logo: {
    width: 360,
    height: 110,
    objectFit: 'cover',
  },
  textWhite: {
    color: 'white'
  }
}));

const Login: FC = () => {
  const classes = useStyles();
  const { is2FAEnabled, isAuthenticated } = useAuth() as any;
  const [isAuthenticatedLocal, setIsAuthenticatedLocal] = useState<boolean>(false);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const currentTime = Date.now() / 1000;

    const decoded: any = jwtDecode(accessToken);

    return decoded.exp > currentTime;
  };

  setTimeout(() => {
    if (isValidToken && localStorage.getItem('accessToken')?.length > 0) {
      setIsAuthenticatedLocal(isAuthenticated);
    } else if (!isValidToken) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.reload();
    } else {
      setIsAuthenticatedLocal(true);
    }
    if (isAuthenticated && is2FAEnabled) {
      window.history.back();
    }
  }, 1000);

  const partnerLogo = getPartnerLogo();

  return (
    <>
      <Helmet>
        <title>{`${t('login_sign_in')} ${process.env.REACT_APP_PAGE_TITLE_APPENDING_TITLE}`}</title>
      </Helmet>
      {!isAuthenticatedLocal ? (
        <Grid
          container
          className={classes.imagesWrapper}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={clsx(classes.leftHeroImage, classes.fullPageHero)}
          >
            <a href="/">
              <div className={classes.leftHeroWrapper}>
                <div className={classes.leftSideLogo}>
                  <img
                    className={classes.logo}
                    src={partnerLogo}
                    alt="Logo"
                  />
                </div>
              </div>
            </a>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          className={classes.imagesWrapper}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className={classes.leftHeroImage}
          >
            <a href="/">
              <div className={classes.leftHeroWrapper}>
                <div className={classes.leftSideLogo}>
                  <img
                    className={classes.logo}
                    src={partnerLogo}
                    alt="Logo"
                  />
                </div>
              </div>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className={classes.rightHeroImage}
          >
            <LoginJWT />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Login;
