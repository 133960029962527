/* eslint-disable jsx-a11y/iframe-has-title */
import ReactPDF from '@intelllex/react-pdf';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import TimeIcon from 'src/icons/X';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      height: '100%',
      borderRadius: 4,
    },
    '& .MuiPaper-root': {
      overflow: 'unset !important'
    },
    '& .MuiDialogContent-root': {
      boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.25) !important'
    }
  },
  deleteBtn: {
    color: '#AB3535',
    '&:hover': {
      background: 'transparent'
    }
  },
  cancelBtn: {
    color: '#6b778c',
    '&:hover': {
      background: 'transparent'
    }
  },
  downloadButton: {
    borderRadius: 4,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      marginBottom: 15,
      width: '100%'
    }
  },
  loadingCircle: {
    color: '#006699',
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
}));

interface LegalDocumentModalProps {
  openLegalDocumentModal: any;
  closeLegalDocumentModal: any;
  selectedLegalDocument: any;
}

const LegalDocumentModal: FC<LegalDocumentModalProps> = (props) => {
  const classes = useStyles();
  const { openLegalDocumentModal, closeLegalDocumentModal, selectedLegalDocument } = props;
  const locale = localStorage.getItem('locale');
  const { isAuthenticated } = useAuth();

  return (
    <Dialog
      open={openLegalDocumentModal}
      onClose={closeLegalDocumentModal}
      fullScreen
      maxWidth="xl"
      sx={{
        height: '100%',
      }}
      fullWidth
      className={classes.root}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Grid
            item
            xs={12}
            sm={9}
          >
            <Typography
              color="textPrimary"
              variant="h5"
            >
              { selectedLegalDocument?.name[locale] }
            </Typography>
            {!isAuthenticated && (
              <Typography
                color="textPrimary"
                variant="h6"
              >
                { selectedLegalDocument?.title[locale] }
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
          >
            {/* <Button
              className={classes.downloadButton}
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              id="login_button"
            >
              Download
            </Button> */}
            <IconButton
              aria-label="close"
              onClick={closeLegalDocumentModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <TimeIcon />
            </IconButton>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ReactPDF
          url={`${process.env.REACT_APP_IMAGE_UPLOAD_URL}/images/user/${selectedLegalDocument?.url}`}
          showProgressBar
          showToolbox
        />
      </DialogContent>
    </Dialog>
  );
};

LegalDocumentModal.propTypes = {
  openLegalDocumentModal: PropTypes.bool,
  closeLegalDocumentModal: PropTypes.func,
};

export default LegalDocumentModal;
