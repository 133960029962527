import type { LegalDocument, LegalDocumentOverviewProps } from 'src/types/legal_document';

import axios from 'src/utils/axios';
import parse from 'src/utils/parse';
import { isUndefined } from 'lodash';
import logger from 'src/utils/logger';
import i18next from 'i18next';

class LegalDocumentApi {
  async create(legalDocument: LegalDocument): Promise<LegalDocument> {
    return new Promise((resolve, reject) => {
      const prop = 'submit';
      delete legalDocument[prop];
      // Create Legal Document
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/createLegalDoc`, legalDocument).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async edit(legalDocument: LegalDocument, legalDocumentId: string): Promise<LegalDocument> {
    return new Promise((resolve, reject) => {
      const prop = 'submit';
      delete legalDocument[prop];
      // Update Legal Document
      axios.put(`${process.env.REACT_APP_DEVICES_URL}/editLegalDoc/${legalDocumentId}`, legalDocument).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async getAllLegalDocumentAutoComplete(search: string): Promise<LegalDocument[]> {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllLegalDocumentAutoComplete`, {
        search
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllLegalDocTypes(): Promise<LegalDocument[]> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/getAllLegalDocTypes`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDefaultLegalDocument(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/getDefaultLegalDocument`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAll(page: number, limit: number, currentTab: number, search: string, sortColumn: any, sortDirection: boolean): Promise<LegalDocumentOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All LegalDocument

      let sortList = {};
      if (sortColumn.nameEn) {
        sortList = {
          name: {
            locale: 'en',
            order: sortDirection ? 'ASC' : 'DESC',
          },
        };
      }

      if (sortColumn.documentType) {
        sortList = {
          legal_doc_type: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.url) {
        sortList = {
          url: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.checkboxLabel) {
        sortList = {
          title: {
            locale: 'en',
            order: sortDirection ? 'ASC' : 'DESC',
          },
        };
      }

      if (sortColumn.version) {
        sortList = {
          version: {
            locale: 'en',
            order: sortDirection ? 'ASC' : 'DESC',
          },
        };
      }

      if (sortColumn.updatedAt) {
        sortList = {
          updatedAt: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.isDefault) {
        sortList = {
          isDefault: sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList,
        search
      };

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      if (search?.length === 0) {
        const prop = 'search';
        delete filter[prop];
      }

      if (!isUndefined(currentTab)) {
        if (currentTab !== -1) {
          filter.default_legal_doc = !!currentTab;
        }
      }

      axios.post(`${process.env.REACT_APP_DEVICES_URL}/getAllLegalDocs`, filter).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getById(legalDocumentId: string): Promise<LegalDocument> {
    return new Promise((resolve, reject) => {
      // Get By Id LegalDocument
      axios.get(`${process.env.REACT_APP_DEVICES_URL}/legalDoc/${legalDocumentId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async deActivate(legalDocumentId: string, isActive: number): Promise<LegalDocument> {
    return new Promise((resolve, reject) => {
      // De-active LegalDocument
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/delete/${legalDocumentId}`, {
        is_activated: !isActive
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async setDefaultLegalDocumentById(legalDocumentId: any, legalDocumentTypeId: any): Promise<LegalDocument> {
    return new Promise((resolve, reject) => {
      // De-active LegalDocument
      axios.patch(`${process.env.REACT_APP_DEVICES_URL}/setDefaultLegalDocumentById/${legalDocumentId}`, {
        legal_doc_type_id: legalDocumentTypeId
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkDeleteLegalDocs(legalDocumentIds: string[]): Promise<LegalDocument> {
    return new Promise((resolve, reject) => {
      // Bulk Delete LegalDocuments
      axios.delete(`${process.env.REACT_APP_DEVICES_URL}/bulkDeleteLegalDocs`, {
        data: {
          legalDocIds: legalDocumentIds
        }
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }
}

export const legalDocumentApi = new LegalDocumentApi();
